import React, { useEffect } from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';

import { Text, TextButtonPriority, TextTypography } from 'wix-ui-tpa';

import { BlackAndWhiteTextButton } from 'common/components/BlackAndWhiteTextButton';

import { GroupSettingsContext } from '../../GroupSettingsContext';

import { MemberPermissions } from './MemberPermissions';
import { GroupQuestions } from './GroupQuestions';
import { EDIT_QUESTIONS_LINK } from './dataHooks';

import { st, classes } from './ModerateTab.st.css';

interface IModerateTabProps {
  groupId: string;
}

export function ModerateTab(props: IModerateTabProps) {
  const { t } = useTranslation();

  const groupSettings = React.useContext(GroupSettingsContext);

  const [dialogs, setDialogs] = React.useState({
    permissions: false,
    questions: false,
  });

  useEffect(() => {
    groupSettings.set({
      submitButtonProps: { hidden: true },
    });
  }, []);

  return (
    <>
      <section className={classes.section}>
        <Text
          tagName="h3"
          className={classes.title}
          typography={TextTypography.smallTitle}
        >
          {t('groups-web.settings.moderate.permissions.title')}
        </Text>
        <Text
          tagName="p"
          className={classes.bodyText}
          typography={TextTypography.runningText}
        >
          {t('groups-web.settings.moderate.permissions.caption')}
        </Text>
        <BlackAndWhiteTextButton
          priority={TextButtonPriority.link}
          onClick={handleDialogOpen('permissions')}
        >
          {t('groups-web.settings.moderate.permissions.edit')}
        </BlackAndWhiteTextButton>
      </section>
      <section className={classes.section}>
        <Text
          tagName="h3"
          className={classes.title}
          typography={TextTypography.smallTitle}
        >
          {t('groups-web.settings.moderate.questions.title')}
        </Text>
        <Text
          tagName="p"
          className={classes.bodyText}
          typography={TextTypography.runningText}
        >
          {t('groups-web.settings.moderate.questions.caption')}
        </Text>
        <BlackAndWhiteTextButton
          data-hook={EDIT_QUESTIONS_LINK}
          priority={TextButtonPriority.link}
          onClick={handleDialogOpen('questions')}
        >
          {t('groups-web.settings.moderate.questions.add')}
        </BlackAndWhiteTextButton>
      </section>
      <MemberPermissions
        groupId={props.groupId}
        isOpen={dialogs.permissions}
        onClose={handleDialogClose('permissions')}
      />
      <GroupQuestions
        groupId={props.groupId}
        isOpen={dialogs.questions}
        onClose={handleDialogClose('questions')}
      />
    </>
  );

  function handleDialogOpen(dialog: keyof typeof dialogs) {
    return () => {
      setDialogs((dialogs) => ({ ...dialogs, [dialog]: true }));
    };
  }

  function handleDialogClose(dialog: keyof typeof dialogs) {
    return () => setDialogs((prev) => ({ ...prev, [dialog]: false }));
  }
}
