import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';

import { PrivacyStatus } from '@wix/ambassador-social-groups-v2-group/types';

import { Dropdown, DropdownOptionProps } from 'wix-ui-tpa';

import { Private, Public, Secret, Visible } from '../Icons';

import { classes, st } from './GroupPrivacySelect.st.css';

interface IGroupPrivacySelectProps {
  value: PrivacyStatus;

  onChange(privacy: PrivacyStatus): void;
}

export const GroupPrivacySelect: React.FC<IGroupPrivacySelectProps> = (
  props,
) => {
  const { onChange, value } = props;
  const { t } = useTranslation();

  return (
    <>
      <Dropdown
        upgrade
        onChange={handleChange}
        initialSelectedId={
          value === PrivacyStatus.SECRET ? PrivacyStatus.PRIVATE : value
        }
        className={st(classes.dropdown)}
        data-hook="group-privacy-select"
        label={t('groups-web.settings.type')}
        options={[
          {
            id: PrivacyStatus.PUBLIC,
            value: t('groups-web.types.public.name'),
            subtitle: t('groups-web.types.public.description'),
            icon: (
              <Public
                className={classes.icon}
                data-hook={`group-privacy-option-${PrivacyStatus.PUBLIC}`}
              />
            ),
          },
          {
            id: PrivacyStatus.PRIVATE,
            value: t('groups-web.types.private.name'),
            subtitle: t('groups-web.types.private.description'),
            icon: (
              <Private
                className={classes.icon}
                data-hook={`group-privacy-option-${PrivacyStatus.PRIVATE}`}
              />
            ),
          },
        ]}
      />
      {[PrivacyStatus.PRIVATE, PrivacyStatus.SECRET].includes(value) && (
        <Dropdown
          upgrade
          onChange={handleChange}
          initialSelectedId={value}
          className={st(classes.dropdown)}
          data-hook="group-visibility-dropdown"
          label={t('groups-web.settings.visibility')}
          options={[
            {
              id: PrivacyStatus.PRIVATE,
              value: t('groups-web.visibility.visible'),
              subtitle: t('groups-web.visibility.visible.label'),
              icon: (
                <Visible
                  className={classes.icon}
                  data-hook={`group-visibility-option--${PrivacyStatus.PRIVATE}`}
                />
              ),
            },
            {
              id: PrivacyStatus.SECRET,
              value: t('groups-web.visibility.hidden'),
              subtitle: t('groups-web.visibility.hidden.label'),
              icon: (
                <Secret
                  className={classes.icon}
                  data-hook={`group-visibility-option--${PrivacyStatus.SECRET}`}
                />
              ),
            },
          ]}
        />
      )}
    </>
  );

  function handleChange(option: DropdownOptionProps) {
    onChange(option.id as PrivacyStatus);
  }
};

GroupPrivacySelect.displayName = 'GroupTypeSelect';
