import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useBi, useTranslation } from '@wix/yoshi-flow-editor';
import { groupActionClick } from '@wix/bi-logger-groups/v2';

import { IconButton, IconButtonSkins } from 'wix-ui-tpa';
import ChevronLeftIcon from 'wix-ui-icons-common/on-stage/ChevronLeft';
import ShareIcon from 'wix-ui-icons-common/on-stage/Share';

import { selectGroupSlug, selectIsGroupSecret } from 'store/groups/selectors';
import { UISref, useHref } from 'common/router';

import { GroupActions } from 'Group/Widget/Header/GroupActions';

import { classes } from './MobileControls.st.css';
import { ShareModal } from 'common/components/ShareModal';

interface IMobileControlsProps {
  groupId: string;

  withBackButton?: boolean;
}

export function MobileControls(props: IMobileControlsProps) {
  const { groupId, withBackButton } = props;

  const bi = useBi();
  const { t } = useTranslation();

  const slug = useSelector(selectGroupSlug(groupId));
  const isSecretGroup = useSelector(selectIsGroupSecret(groupId));

  const [isOpen, setIsOpen] = useState(false);

  const groupUrl = useHref(
    'group.discussion.feed',
    { slug },
    { absolute: true },
  );

  return (
    <div className={classes.root}>
      <div className={classes.actions}>
        {withBackButton && (
          <UISref state="groups">
            <IconButton
              as="a"
              data-hook="arrow-back-button"
              skin={IconButtonSkins.Line}
              icon={<ChevronLeftIcon width="24px" height="24px" />}
            />
          </UISref>
        )}
      </div>
      <div className={classes.actions}>
        {isSecretGroup ? null : (
          <IconButton
            aria-label="Share"
            data-hook="share-button"
            skin={IconButtonSkins.Full}
            icon={<ShareIcon width="24px" height="24px" />}
            onClick={handleOpenShareModal('arrow')}
          />
        )}
        <GroupActions
          groupId={groupId}
          onShare={handleOpenShareModal('menu')}
        />
      </div>
      <ShareModal
        isOpen={isOpen}
        shareUrl={groupUrl}
        onClose={handleCloseShareModal}
        title={t('groups-web.group.actions.share.group')}
      />
    </div>
  );

  function handleCloseShareModal() {
    setIsOpen(false);
  }

  function handleOpenShareModal(origin: string) {
    return () => {
      setIsOpen(true);

      bi.report(
        groupActionClick({
          origin,
          action: 'share',
          group_id: groupId,
        }),
      );
    };
  }
}
