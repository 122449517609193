import React from 'react';
import { useSelector } from 'react-redux';
import { useBi, useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { groupActionClick } from '@wix/bi-logger-groups/v2';
import { IconButton, IconButtonSkins } from 'wix-ui-tpa';
import type { TPAComponentProps } from 'wix-ui-tpa/dist/src/types';

import ShareIcon from 'wix-ui-icons-common/on-stage/Share';

import { selectGroupSlug, selectIsGroupSecret } from 'store/groups/selectors';

import { useHref } from 'common/router';
import { ShareModal } from 'common/components/ShareModal';

import { GroupMembershipButton } from 'Group/Widget/GroupMembership';
import { InviteMembers } from '../InviteMembers';
import { GroupActions } from '../GroupActions';

import { GroupMeta } from './GroupMeta';

import { st, classes } from './GroupDetails.st.css';

interface IGroupDetailsProps extends TPAComponentProps {
  groupId: string;
}

export function GroupDetails(props: IGroupDetailsProps) {
  const { groupId } = props;

  const bi = useBi();
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();

  const slug = useSelector(selectGroupSlug(groupId));
  const isSecretGroup = useSelector(selectIsGroupSecret(groupId));

  const [isOpen, setIsOpen] = React.useState(false);

  const groupUrl = useHref(
    'group.discussion.feed',
    { slug },
    { absolute: true },
  );

  return (
    <div className={st(classes.root, { isMobile }, props.className)}>
      <GroupMeta groupId={groupId} />

      <div className={classes.actions}>
        <InviteMembers groupId={groupId} />

        <GroupMembershipButton
          groupId={groupId}
          biOrigin="group_feed_top_banner_btn"
          className={classes.membershipButton}
        />

        {!isSecretGroup && !isMobile && (
          <IconButton
            aria-label="Share"
            data-hook="share-button"
            skin={IconButtonSkins.Full}
            icon={<ShareIcon width="24px" height="24px" />}
            onClick={handleOpenShareModal('arrow')}
          />
        )}
        {!isMobile && (
          <GroupActions
            className={classes.settings}
            groupId={groupId}
            onShare={handleOpenShareModal('menu')}
          />
        )}
      </div>
      <ShareModal
        isOpen={isOpen}
        onClose={handleCloseShareModal}
        shareUrl={groupUrl}
        title={t('groups-web.group.actions.share.group')}
      />
    </div>
  );

  function handleCloseShareModal() {
    setIsOpen(false);
  }

  function handleOpenShareModal(origin: string) {
    return () => {
      setIsOpen(true);

      bi.report(
        groupActionClick({
          origin,
          action: 'share',
          group_id: groupId,
        }),
      );
    };
  }
}
