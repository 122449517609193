import React from 'react';

import { ButtonProps } from 'wix-ui-core/dist/src/components/button-next/button-next';

export interface IGroupSettingsContext {
  title: string;
  submitButtonProps: ButtonProps & {
    loading?: boolean;
  };

  set(options: Partial<Omit<IGroupSettingsContext, 'set'>>): void;
}

export const GroupSettingsContext = React.createContext(
  {} as IGroupSettingsContext,
);
